import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const NavLinks = () => (
    <ul className={`header-nav-list ${isMobile && isMenuOpen ? 'open' : ''}`}>
      <li>
        <Link to="/output" className="header-nav-link" onClick={() => setIsMenuOpen(false)}>
          &lt;OutPut /&gt;
        </Link>
      </li>
      <li>
        <Link to="/goal" className="header-nav-link" onClick={() => setIsMenuOpen(false)}>
          &lt;Goal /&gt;
        </Link>
      </li>
      <li>
        <Link to="/about" className="header-nav-link" onClick={() => setIsMenuOpen(false)}>
          &lt;About_Us /&gt;
        </Link>
      </li>
      <li>
        <Link to="/contact" className="header-nav-link" onClick={() => setIsMenuOpen(false)}>
          &lt;Contact_Us /&gt;
        </Link>
      </li>
    </ul>
  );

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">
          <span className="logo-text">&lt;KorLegends /&gt;</span>
        </Link>
        <nav className="header-nav-container">
          <NavLinks />
        </nav>
        {isMobile && (
          <button className="menu-button" onClick={toggleMenu}>
            <Menu size={24} />
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;