import { Github, Heart, Instagram } from "lucide-react";
import React from "react";
import "./Footer.css";

const Footer = () => {
  const siteName = process.env.REACT_APP_WEBSITE_NAME || "KOACA";
  const instaURL = process.env.REACT_APP_INSTAGRAM_URL || "https://www.instagram.com/korlegend_official/";
  const githubURL = process.env.REACT_APP_GITHUB_URL || "https://github.com/KORLEGENDS";

  const socialLinks = [
    { url: instaURL, icon: Instagram, label: "Instagram" },
    { url: githubURL, icon: Github, label: "GitHub" },
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="copyright">
          &copy; {new Date().getFullYear()} {siteName}
          <span className="made-with-love">
            Made with <Heart size={16} className="heart-icon" />
          </span>
        </p>
        <div className="admin-social-links">
          {socialLinks.map(({ url, icon: Icon, label }) => (
            <a
              key={label}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <Icon size={20} />
              <span>{label}</span>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;